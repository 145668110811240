import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import type { QueryAllBlocksRecursivelyResult } from '../contentful/QueryAllBlocksRecursivelyResult'

type BlockWithId = { sys: { id: string } }

export function getBlockChildren(
  block: QueryAllBlocksRecursivelyResult,
  items: (BlockWithId | null)[] | undefined
) {
  const blockTyped = block as QueryAllBlocksRecursivelyResult
  const ids = map(items, (i) => i?.sys?.id)
  return filter(blockTyped.childBlockData, (child) =>
    includes(ids, child?.sys?.id)
  )
}
