import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'

// the vertical padding values are adjusted using FullWidthBackgroundContainer values
// original values are tw`p-px-24 md:(p-px-48) lg:(p-px-160)` as per designer specs
const additionalVerticalPadding = tw`md:(padding-top[24px] padding-bottom[48px]) ml:(padding-top[112px] padding-bottom[160px])`
const additionalSidePadding = tw`p-px-48 md:(p-0)`

export const ChildrenBlockSpacingContainer: FunctionComponent<React.PropsWithChildren<{
  withExtraVerticaLPadding?: boolean
  withExtraSideLPadding?: boolean
}>> = styled.div(
  ({
    withExtraVerticaLPadding,
    withExtraSideLPadding,
  }: {
    withExtraVerticaLPadding: boolean
    withExtraSideLPadding: boolean
  }) => [
    tw`space-y-px-32 ml:(space-y-px-32) lg:(space-y-px-32)`,
    withExtraVerticaLPadding && additionalVerticalPadding,
    withExtraSideLPadding && additionalSidePadding,
  ]
)
