import type { TwoColumnLayoutFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import tw, { styled } from 'twin.macro'
import { ChildrenBlockSpacingContainer } from '../contentful/ChildrenBlockSpacingContainer'
import { ContentfulBlocks } from '../contentful/ContentfulBlocks'
import { TwoColumnLayoutContactAndNewsletterCTAs } from './TwoColumnLayoutContactAndNewsletterCTAs'

import type { QueryAllBlocksRecursivelyResult } from '../contentful/QueryAllBlocksRecursivelyResult'
import { getBlockChildren } from './block.utils'

export const TwoColumnContainer = styled.div<{
  mobileColumnOrder: TwoColumnLayoutMobileColumnOrder
}>(({ mobileColumnOrder }) => [
  tw`flex space-y-px-48`,
  mobileColumnOrder === 'LeftOverRight' ? tw`flex-col` : tw`flex-col-reverse`,
  tw`md:(flex-row space-x-px-48 space-y-0) lg:space-x-px-64`,
])

export const StyledColumn = styled.div<{
  alignContentRight?: boolean
  hasOffset?: boolean
  verticalCenter?: boolean
  withOverhang?: boolean
  left: boolean
}>(({ hasOffset, verticalCenter, withOverhang, left, alignContentRight }) => [
  alignContentRight ? tw`text-right` : '',
  hasOffset ? tw`md:pt-px-80 lg:pt-px-196` : '',
  tw`flex-1 flex flex-col max-w-full overflow-hidden`,
  verticalCenter ? tw`justify-center` : '',
  withOverhang && (left ? tw`md:(-mr-px-196 z-10)` : tw`md:(-ml-px-196)`),
])

type TwoColumnLayoutVariant = '50/50' | '60/60'
export type TwoColumnLayoutMobileColumnOrder = 'LeftOverRight' | 'RightOverLeft'

export const TwoColumnLayout: FunctionComponent<React.PropsWithChildren<{
  block: QueryAllBlocksRecursivelyResult<TwoColumnLayoutFragment>
}>> = ({ block }) => {
  if (!block) return null
  const variant: TwoColumnLayoutVariant = (block.variant as any) || '50/50'
  const mobileColumnOrder: TwoColumnLayoutMobileColumnOrder =
    (block.mobileColumnOrder as any) || 'LeftOverRight'

  const withOverhang = variant === '60/60'

  const appendContactAndNewsletterCtAs = !!block.appendContactAndNewsletterCtAs

  return (
    <TwoColumnContainer mobileColumnOrder={mobileColumnOrder}>
      <StyledColumn
        alignContentRight={block.leftColumnTextRight ?? undefined}
        verticalCenter={block.verticalCenterLeftColumn ?? undefined}
        withOverhang={withOverhang}
        left={true}
      >
        <ChildrenBlockSpacingContainer>
          <ContentfulBlocks
            blocks={getBlockChildren(block, block.leftColumnCollection?.items)}
          />
        </ChildrenBlockSpacingContainer>
      </StyledColumn>
      <StyledColumn
        verticalCenter={block.verticalCenterRightColumn ?? undefined}
        hasOffset={block.rightColumnOffset ?? undefined}
        withOverhang={withOverhang}
        left={false}
      >
        <ChildrenBlockSpacingContainer>
          <ContentfulBlocks
            blocks={getBlockChildren(block, block.rightColumnCollection?.items)}
          />
        </ChildrenBlockSpacingContainer>
        {appendContactAndNewsletterCtAs && (
          <TwoColumnLayoutContactAndNewsletterCTAs />
        )}
      </StyledColumn>
    </TwoColumnContainer>
  )
}
