import { useUserIdWithLoading } from '@dreamstack/auth'
import {
  newsletterInputClassName,
  SimpleMuiButton,
} from '@dreamstack/feature-components'
import { useLocale, useTranslation } from '@dreamstack/i18n'
import { ArrowDown } from '@dreamstack/icons'
import { getOrCreateAnonymousId } from '@dreamstack/tracking/src/getOrCreateAnonymousId'
import { trpc } from '@dreamstack/trpc/client'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'
import { scrollToTarget } from '../utils/scrollToTarget'

const Wrapper = styled.div(() => [
  tw`bg-accentroGray-full text-white`,
  tw`flex flex-col`,
  tw`space-y-px-16`,
  tw`p-px-16 items-center`,
  tw`md:(ml-4 -mt-px-64 z-10 w-max p-px-32 items-end)`,
])

export const TwoColumnLayoutContactAndNewsletterCTAs: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const locale = useLocale()
  const { userId } = useUserIdWithLoading()
  const contactHref = locale === 'de' ? '/kontakt' : '/contact'
  const t = useTranslation()
  const { mutateAsync: trackButtonClickServer } =
    trpc.anonymousTracking.trackEvent.useMutation()
  const gotoNewsletter = () => {
    scrollToTarget(`.${newsletterInputClassName}`)
    setTimeout(() => {
      const input = document.querySelector(
        `.${newsletterInputClassName}`
      ) as HTMLInputElement
      input.focus()
    }, 500)
  }
  const router = useRouter()

  const trackMakeAppointmentClick = async () => {
    const anonymousUserId = getOrCreateAnonymousId()
    await trackButtonClickServer({
      eventName: 'buttonClick',
      userId,
      anonymousUserId,
      data: {
        buttonAction: 'Beratungsgepräch vereinbaren',
        url: router.asPath,
      },
    })
  }
  // This event only tracks the click on the newsletter button on the page, not the actual subscription
  // The actual subscription is tracked as a formSubmission Event
  const trackSubscribeToNewsletterClick = async () => {
    const anonymousUserId = getOrCreateAnonymousId()
    await trackButtonClickServer({
      eventName: 'buttonClick',
      userId,
      anonymousUserId,
      data: {
        buttonAction: 'Newsletter abonnieren',
        url: router.asPath,
      },
    })
  }

  return (
    <Wrapper>
      <Link href={contactHref} passHref tw="text-accentroWhite-full">
        <SimpleMuiButton color="inherit" onClick={trackMakeAppointmentClick}>
          {t('accentro:arrangeConsultation')}
        </SimpleMuiButton>
      </Link>
      <div tw="text-accentroWhite-full">
        <SimpleMuiButton
          onClick={() => {
            gotoNewsletter()
            trackSubscribeToNewsletterClick()
          }}
          variant="text"
          color="inherit"
          endIcon={
            <ArrowDown tw="text-accentroWhite-full h-px-24 w-px-24 lg:(h-px-48 w-px-48)" />
          }
        >
          {t('accentro:subscribeToNewsletter')}
        </SimpleMuiButton>
      </div>
    </Wrapper>
  )
}
